import './WhyChoose.css'

export const WhyChoose = () => (<div id='why-choose'>
    <h1>Why Choose Renovare?</h1>
    <p>We are dedicated to making your life cleaner, healthier, and more convenient.</p>
    <p>We tailor our services to meet your unique needs, ensuring the highest level of satisfaction.</p>

    <ul>
        <li>We're licensed and insured.</li>
        <li>We have a track record of providing quality cleaning services since 2012.</li>
        <li>Instead of locking our clients into unreasonable, year-long contracts, we earn your business through consistently high-quality service.</li>
        <li>Communication is key! We regularly ask for your feedback, which enables us to cater to the particular needs of you or your organization.</li>
    </ul>
</div>)