import './Home.css'
import { About } from '../../Components/About/About'
import { Contact } from '../../Components/Contact/Contact'
import { Reviews } from '../../Components/Reviews/Reviews'
import { Services } from '../../Components/Services/Services'
import { WhyChoose } from '../../Components/WhyChoose/WhyChoose'
import { PricingDisclosure } from '../../Components/PricingDisclosure/PricingDisclosure'
// import { Testimonials } from '../../Components/Testimonials/Testimonials'
import FadeIn from 'react-fade-in'

export const Home = () => (<div id='home'>
    <div id='hero-image-container'>
        <div id='hero-image'>
            <div>
                <img src='https://renovare-services-com.s3-us-east-2.amazonaws.com/renovare-logo-light-cropped.svg' alt='Renovare Services logo' id='hero-logo' />
                {/* <h1>Renovare Services</h1> */}
                <p>We are dedicated to making your life cleaner, healthier, and more convenient.</p>
                <p>We tailor our services to meet your unique needs, ensuring the highest level of satisfaction.</p>
            </div>
        </div>
    </div>
    <FadeIn delay={400} transitionDuration={900}>
        <Services />
    </FadeIn>
    <FadeIn delay={900} transitionDuration={900}>
        <WhyChoose />
    </FadeIn>
    {/* <FadeIn delay={900} transitionDuration={900}>
        <Testimonials />
    </FadeIn> */}
    <div className='div-split-view'>
        <FadeIn delay={1000} transitionDuration={900}>
            <About />
        </FadeIn>
        <div>
            <Contact />
            <Reviews />
            <PricingDisclosure />
        </div>
    </div>
</div>)