import './App.css'

import { useLayoutEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import { Nav } from '../Nav/Nav'
import { Copyright } from '../../Components/Copyright/Copyright'
import { Error } from '../../Components/Error/Error'

import { Home } from '../../Pages/Home/Home'

const ScrollToTopWrapper = ({children}:{children:any}) => {
    const location = useLocation()

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    
    return children
}

function App() {
    return (<Router>
        <ScrollToTopWrapper>
            <Nav />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='*' element={<Error />} />
            </Routes>
            <Copyright />
        </ScrollToTopWrapper>
    </Router>)
}

export default App