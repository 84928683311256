import './Nav.css'
import { useState } from 'react'

const CloseX = () => (<svg id='close-icon' xmlns='http://www.w3.org/2000/svg' viewBox='144 144 224 224' width='16' height='16'><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M368 368L144 144M368 144L144 368'/></svg>)

const HamburgerMenu = () => (<svg id='hamburger-menu' viewBox='0 0 100 80' width='20' height='20'><rect width='95' height='7'></rect><rect y='30' width='95' height='7'></rect><rect y='60' width='95' height='7'></rect></svg>)

export const Nav = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    function toggleMenu() {
        menuOpen ? setMenuOpen(false) : setMenuOpen(true)
    }

    return (<div id='nav'>
        <div id='desktop-nav' className='desktopOnly'>
            <a href='#services'>Services</a>
            <a href='#why-choose'>Choose Renovare</a>

            <img src='https://renovare-services-com.s3-us-east-2.amazonaws.com/renovare-logo-icon-dark.svg' alt='Renovare Services logo' />

            <a href='#about'>About Us</a>
            <a href='#contact'>Contact Us</a>
        </div>

        <div id='mobile-nav' className='mobileOnly'>
            <span id='hamburger-menu-icon-region' onClick={() => toggleMenu()}>
                {
                    menuOpen ? (<CloseX />) : (<HamburgerMenu />)
                }
            </span>

            {
                menuOpen ?
                (<>
                    <a href='#services' onClick={() => toggleMenu()}>Services</a>
                    <a href='#why-choose' onClick={() => toggleMenu()}>Choose Renovare</a>
                    <a href='#about' onClick={() => toggleMenu()}>About Us</a>
                    <a href='#contact' onClick={() => toggleMenu()}>Contact Us</a>
                </>)
                :
                (null)
            }
        </div>
    </div>)
}