import './Reviews.css'
import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'

const CheckmarkIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Checkmark Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M352 176L217.6 336 160 272'/></svg>)

const FormSending = () => (<div id='form-sending'>
    <p>Submission in progress. Please do not close this window.</p>
</div>)

const FormDone = () => (<div id='form-done'>
    <CheckmarkIcon />
    <p>Successfully sent.</p>
</div>)

function ReviewsForm(props: {title:String}) {
    const form = useRef(null)

    const [name, setName] = useState(String)
    const [email, setEmail] = useState(String)
    const [reviewText, setReviewText] = useState(String)
    const [loading, setLoading] = useState(false)
    const [submissionError, setSubmissionError] = useState({
        status: false,
        message: ''
    })
    const [formSubmitted, setformSubmitted] = useState(false)

    function verifyAndDeploySubmission(e:any) {
        e.preventDefault()
        setLoading(true)
        setSubmissionError({
            status: false,
            message: ''
        })

        if (name !== '') {
            if (email !== '') {
                if (email.includes('@')) {
                    if (email.includes('.')) {
                        if (reviewText !== '') {
                            handleSubmission()
                        } else {
                            setLoading(false)
                            setSubmissionError({
                                status: true,
                                message: 'Sorry, there was a problem submitting that information. It looks like the "Review or Comment" field is blank.'
                            })
                        }
                    } else {
                        setLoading(false)
                        setSubmissionError({
                            status: true,
                            message: 'Sorry, there was a problem submitting that information. It looks like your email may be invalid.'
                        })
                    }
                } else {
                    setLoading(false)
                    setSubmissionError({
                        status: true,
                        message: 'Sorry, there was a problem submitting that information. It looks like your email may be invalid.'
                    })
                }
            } else {
                setLoading(false)
                setSubmissionError({
                    status: true,
                    message: 'Sorry, there was a problem submitting that information. It looks like the Email field is blank.'
                })
            }
        } else {
            setLoading(false)
            setSubmissionError({
                status: true,
                message: 'Sorry, there was a problem submitting that information. It looks like the Name field is blank.'
            })
        }
    }

    function handleSubmission() {
        // Implementation for EmailJS: https://www.emailjs.com/docs/examples/reactjs/
        const currentForm = form.current
        // Wondering why we're setting `form.current` to `currentForm` instead of, yunno, just using `form.current` in our submission code? [🌈 Cuz TypeScript. 🌈](https://stackoverflow.com/questions/72788643/htmlformelement-null-is-not-assignable-to-parameter-of-type-string-typescript)
        if (currentForm == null) return

        emailjs.sendForm('service_x93ocir', 'template_477107t', currentForm, '-DTo60vZNq3O5fQPb')
            .then((result) => {
                console.log(result.text)
                formReset()
            }, (error) => {
                console.log(error.text)
                setLoading(false)
                setSubmissionError({
                    status: true,
                    message: error.text
                })
            })
    }

    function formReset() {
        // Clears inputs from form
        setName('')
        setEmail('')
        setReviewText('')
        Array.from(document.querySelectorAll('input')).forEach(input => input.value='')

        setSubmissionError({
            status: false,
            message: ''
        })
        setLoading(false)
        setformSubmitted(true)
    }
    
    return (<form id='reviews-form'>
        <h1>{props.title}</h1>
        <label>Name</label>
        <input type='text' name='name' onChange={(e) => setName(e.target.value)} />
        <label>Email</label>
        <input type='text' name='email' onChange={(e) => setEmail(e.target.value)} />
        <label>Review or Comment</label>
        <textarea name='reviewText' rows={5} onChange={(e) => setReviewText(e.target.value)} />
        {loading ? null : <button onClick={(e:any) => verifyAndDeploySubmission(e)}>Submit</button>}
        
        <div id='spinner' className={`${loading ? 'visible' : 'invisible'} contact-form-message`}></div>

        <div id='reviews-form-message'>
            {loading ? (<FormSending />) : null}

            {formSubmitted ? (<FormDone />) : null}

            {submissionError.status ?
            (<div>
                <p>{submissionError.message}</p>
            </div>)
            :
            null}
        </div>
    </form>)
}

export const Reviews = () => (<div id='reviews'>
    <ReviewsForm title={"Reviews & Comments"} />
</div>)