import './About.css'

export const About = () => (<div id='about'>
    <h1>About</h1>
    <img src='https://renovare-services-com.s3-us-east-2.amazonaws.com/downscaled/827FC94E-0401-4C29-B362-1BBBDE66FF09.jpg' alt='Loren and Ashley Martinez' />
    <p>We are a husband-and-wife team. We were both born and raised in the Fort Wayne area. Ashley enjoys spending time with friends and family, our cat Nellie, and her favorite beverage iced tea. Loren loves being active — especially playing soccer on the weekends and enjoying the outdoors.</p>
    <p>But most of all we love providing practical assistance to our community and are thankful to do so as a couple!</p>
    <p>As a family-owned and operated business, we take pride in extending our family values to yours, providing meticulous cleaning services that make your space shine.</p>
    <div id='about-socials'>
        <a href='https://instagram.com/renovareservicesllc' target='_blank' rel='noopener noreferrer'>@renovareservicesllc on Instagram</a>
    </div>
</div>)