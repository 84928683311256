import './Services.css'

const ServicesLibrary = [
    {
        title: "Commercial Cleaning",
        firstDescriptionItem: "We specialize in maintaining your place of business to a high standard. Let us lend a hand in creating a productive and welcoming workspace with our office cleaning solutions. A clean office boosts morale and leaves a lasting impression on clients."
    },
    {
        title: "Window Cleaning",
        firstDescriptionItem: "Discover a brighter view at your home and workplace with expert window cleaning services. Whether it's a once-a-year refresh or a monthly maintenance, add curb appeal with crystal-clear windows today."
    },
    {
        title: "Residential",
        firstDescriptionItem: "Let us assist you with maintaining a healthy home! We offer weekly, biweekly, or one-time cleaning services.",
        secondDescriptionItem: "Move-In/Move-Out Cleaning: Moving is stressful enough. Let us handle the cleaning so you can focus on your new chapter in a sparkling clean space."
    },
    {
        title: "Post-Construction",
        firstDescriptionItem: "After the dust settles and the builders depart, we step in.",
        secondDescriptionItem: "With precise attention to detail, we remove debris and scrub away residues — leaving you with a move-in–ready environment. Ensure your project concludes with the brilliance it deserves."
    }
]

export const Services = () => (<div id='services'>
    <h1>Services</h1>
    <div id='services-services'>
        {
            ServicesLibrary.map((x, i) => {
                if (i === 0) {
                    return (<div className='services-service-item' key={i}>
                        <h1>{x.title}</h1>
                        <p>{x.firstDescriptionItem}</p>
                        {
                            x.secondDescriptionItem ? (<p>{x.secondDescriptionItem}</p>) : (null)
                        }
                    </div>)
                }
                return (null)
            })
        }
        <img src='https://renovare-services-com.s3-us-east-2.amazonaws.com/full-res/24B5316D-22AE-46FB-8152-24A5EF9633E9.jpg' alt='' />
        <img src='https://renovare-services-com.s3-us-east-2.amazonaws.com/full-res/2BB1050D-EF42-492C-ABA3-59507090989F.jpg' alt='' />
        {
            ServicesLibrary.map((x, i) => {
                if (i > 0) {
                    return (<div className='services-service-item' key={i}>
                        <h1>{x.title}</h1>
                        <p>{x.firstDescriptionItem}</p>
                        {
                            x.secondDescriptionItem ? (<p>{x.secondDescriptionItem}</p>) : (null)
                        }
                    </div>)
                }
                return (null)
            })
        }
    </div>
</div>)